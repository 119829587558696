import configEstacio from './estacio';
import configIbmec from './ibmec';
import configIdomed from './idomed';
import { Environment } from './types';
import configUnitoledo from './unitoledo';
import configWyden from './wyden';

const environments: Array<() => Environment> = [
  configUnitoledo.prod,
  configEstacio.prod,
  configIbmec.prod,
  configWyden.prod,
  configIdomed.prod,
  configEstacio.hml,
  configUnitoledo.hml,
  configIbmec.hml,
  configWyden.hml,
  configIdomed.hml,
  configEstacio.browserStack,
  configEstacio.dev,
  configUnitoledo.dev,
  configIbmec.dev,
  configWyden.dev,
  configIdomed.dev,
  configEstacio.local,
  configUnitoledo.local,
  configIbmec.local,
  configWyden.local,
  configIdomed.local,
];

export const getConfig = (): Environment => {
  const contextoAtual = `${window.location.protocol}//${window.location.host}`;
  return (
    environments.map((env) => env()).find((env) => env.contextos.some((ctx) => ctx.host === contextoAtual)) ??
    configEstacio.prod()
  );
};
