import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { getLinkFichaCatalograficaByMarca } from '../commons/menu/biblioteca-virtual/ficha-catalografica/ficha-catalografica.config';
import { getItensMenuConfig } from '../commons/menu/menu.config';
import { Environment } from '../types';

const localHost = 'localhost';

const buildSettings = (): UserManagerSettings => {
  const authDev = localStorage.getItem('authDev') === 'true';
  return {
    authority: authDev
      ? 'https://login.microsoftonline.com/da49a844-e2e3-40af-86a6-c3819d704f49/v2.0/'
      : `http://${localHost}:7788`,
    client_id: authDev ? 'e53aee6c-1ff5-49b3-82cd-483e6288d86e' : 'webapp-aura-local',
    redirect_uri: `http://${localHost}:3013/`,
    post_logout_redirect_uri: `http://${localHost}:3013/`,
    monitorSession: false,
    userStore: new WebStorageStateStore({ store: localStorage }),
    response_type: 'code',
    loadUserInfo: false,
    scope: 'e53aee6c-1ff5-49b3-82cd-483e6288d86e/.default',
  };
};

const local = (): Environment => {
  const settings: UserManagerSettings = buildSettings();
  const apiDev = localStorage.getItem('apiDev') === 'true';
  const apiUrl = localStorage.getItem('apiUrl');
  const apiV2Url = localStorage.getItem('apiV2Url');
  const ambiente = 'local';
  const marca = 'wyden';

  return {
    contextos: [{ host: `http://${localHost}:3013` }],
    ambiente,
    marca,
    idClienteEnsineme: 'A39EAE23-8E99-27B8-82FC-D5ABAF8953CD',
    URL_REDIRECT_ENSINEME: 'https://stecine.azureedge.net/repositorio/redirect/_redirect.html',
    URL_REDIRECT_REDEFINIR_SENHA: 'https://passwordreset.microsoftonline.com/',
    URL_ESQUECEU_SENHA: 'https://passwordreset.microsoftonline.com/',
    API: apiDev ? apiUrl ?? 'https://educador-sava-wyden.estaciodev.net/apis' : `http://${localHost}:5010`,
    API_V2: apiDev
      ? apiV2Url ?? 'https://educador-sava-wyden.estaciodev.net/aura-frontend'
      : `http://${localHost}:5010`,
    URL_ALUNO: `http://${localHost}:3003`,
    URL_FAQ: '/aura-repo/estacio/faq-educador.pdf',
    URL_MANUAL: '/aura-repo/estacio/manual-educador.pdf',
    URL_BASE_CALENDARIO_ACADEMICO: '/aura-repo/calendario-academico/',
    EVENT_LOGIN_ENDPOINT: '/v2/eventos-login/educadores',
    userManagerSettings: settings,
    // TODO: Ligar essa configuração ao ativar o MSAL
    // msalConfiguration: paraMsalConfiguration({ settings }),
    GTM_ID: 'GTM-56HT2PR',
    TAMANHO_PAGINA_RESPOSTA_TRABALHOS: 5,
    ITENS_MENU: getItensMenuConfig({ ambiente, marca }),
    URL_FICHA_CATALOGRAFICA: getLinkFichaCatalograficaByMarca(marca),
  };
};

export default local;
