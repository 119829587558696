import { Getter, ItemMenuConfig, ItemMenuConfigValues, Params } from './menu.types';

export const itensMenuConfig: ItemMenuConfig[] = [
  {
    marcas: ['estacio', 'unitoledo', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    item: 'home',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'unitoledo', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    item: 'turmas',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'unitoledo', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    item: 'biblioteca-virtual',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'unitoledo', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    item: 'calendario-academico',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'unitoledo', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    item: 'ajuda',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'unitoledo', 'ibmec', 'wyden'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    item: 'campus-sia',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'unitoledo', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    item: 'eps',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'unitoledo', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    item: 'acessibilidade',
    habilitado: true,
  },
  {
    marcas: ['estacio'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    item: 'tutoria',
    habilitado: true,
  },
];

export const getItensMenuConfig: Getter<Params, ItemMenuConfigValues[]> = ({ ambiente, marca }: Params) => {
  return itensMenuConfig
    .filter((item) => item.marcas.includes(marca) && item.ambientes.includes(ambiente) && item.habilitado)
    .map((item) => item.item);
};
