import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { getItensMenuConfig } from '../commons/menu/menu.config';
import { paraMsalConfiguration } from '../commons/msal.commons';
import { Environment } from '../types';

const buildSettings = (): UserManagerSettings => ({
  authority: 'http://bs-local.com:7788',
  client_id: 'webapp-aura-local',
  redirect_uri: 'http://bs-local.com:3000/',
  monitorSession: false,
  userStore: new WebStorageStateStore({ store: localStorage }),
  response_type: 'code',
  loadUserInfo: false,
  scope: 'c2cd8cdf-a27d-46e1-a8fd-4778406cba96/.default',
});

const browserStack = (): Environment => {
  const settings: UserManagerSettings = buildSettings();
  const apiUrl = localStorage.getItem('apiUrl');
  const ambiente = 'local';
  const marca = 'estacio';

  return {
    contextos: [{ host: 'http://bs-local.com:3010' }],
    ambiente,
    marca,
    idClienteEnsineme: 'A39EAE23-8E99-27B8-82FC-D5ABAF8953CD',
    URL_REDIRECT_ENSINEME: 'https://stecine.azureedge.net/repositorio/redirect/_redirect.html',
    URL_REDIRECT_REDEFINIR_SENHA: 'https://account.activedirectory.windowsazure.com/ChangePassword.aspx',
    URL_ESQUECEU_SENHA: 'https://passwordreset.microsoftonline.com/',
    API: apiUrl || 'http://bs-local.com:5010',
    API_V2: apiUrl || 'http://bs-local.com:5010',
    URL_ALUNO: 'https://aluno-frontend.estaciodev.net',
    URL_FAQ: '/aura-repo/estacio/faq-educador.pdf',
    URL_MANUAL: '/aura-repo/estacio/manual-educador.pdf',
    URL_BASE_CALENDARIO_ACADEMICO: '/aura-repo/calendario-academico/',
    EVENT_LOGIN_ENDPOINT: '/v2/eventos-login/educadores',
    userManagerSettings: settings,
    msalConfiguration: paraMsalConfiguration({ settings }),
    GTM_ID: 'GTM-56HT2PR',
    TAMANHO_PAGINA_RESPOSTA_TRABALHOS: 5,
    ITENS_MENU: getItensMenuConfig({ ambiente, marca }),
    URL_FICHA_CATALOGRAFICA: `https://ltechnology.com.br/gleison/`,
  };
};

export default browserStack;
