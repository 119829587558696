import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { getLinkFichaCatalograficaByMarca } from '../commons/menu/biblioteca-virtual/ficha-catalografica/ficha-catalografica.config';
import { getItensMenuConfig } from '../commons/menu/menu.config';
import { paraMsalConfiguration } from '../commons/msal.commons';
import { Environment } from '../types';

const buildSettings = (): UserManagerSettings => ({
  authority: 'https://login.microsoftonline.com/da49a844-e2e3-40af-86a6-c3819d704f49/v2.0/',
  client_id: '0c72e37b-5000-4348-8375-a123a48de6c7',
  redirect_uri: 'https://educador-aura-wyden.estaciodev.net',
  post_logout_redirect_uri: 'https://educador-aura-wyden.estaciodev.net',
  scope: '0c72e37b-5000-4348-8375-a123a48de6c7/.default',
  monitorSession: false,
  userStore: new WebStorageStateStore({ store: localStorage }),
  prompt: 'select_account',
  response_type: 'code',
  automaticSilentRenew: true,
  loadUserInfo: false,
});

const hml = (): Environment => {
  const settings: UserManagerSettings = buildSettings();
  const apiUrl = localStorage.getItem('apiUrl');
  const apiV2Url = localStorage.getItem('apiV2Url');
  const ambiente = 'hml';
  const marca = 'wyden';

  return {
    contextos: [{ host: 'https://educador-sava-wyden.estacioqa.net' }],
    ambiente,
    marca,
    idClienteEnsineme: 'A39EAE23-8E99-27B8-82FC-D5ABAF8953CD',
    URL_REDIRECT_ENSINEME: 'https://stecine.azureedge.net/repositorio/redirect/_redirect.html',
    URL_REDIRECT_REDEFINIR_SENHA: 'https://account.activedirectory.windowsazure.com/ChangePassword.aspx',
    URL_ESQUECEU_SENHA: 'https://passwordreset.microsoftonline.com/',
    API: apiUrl ?? 'https://sava-wyden.estacioqa.net/apis',
    API_V2: apiV2Url ?? 'https://hmlapi.estacio.br/homolog/aura-frontend',
    URL_ALUNO: 'https://estudante-sava-wyden.estacioqa.net/',
    URL_FAQ: '/aura-repo/faq-educador.pdf',
    URL_MANUAL: '/aura-repo/manual-educador.pdf',
    URL_BASE_CALENDARIO_ACADEMICO: '/aura-repo/calendario-academico/',
    EVENT_LOGIN_ENDPOINT: '/v2/eventos-login/educadores',
    userManagerSettings: settings,
    msalConfiguration: paraMsalConfiguration({ settings }),
    GTM_ID: 'GTM-56HT2PR',
    TAMANHO_PAGINA_RESPOSTA_TRABALHOS: 5,
    ITENS_MENU: getItensMenuConfig({ ambiente, marca }),
    URL_FICHA_CATALOGRAFICA: getLinkFichaCatalograficaByMarca(marca),
  };
};

export default hml;
