import { Brand } from '@aura/contextos-frontend/dist/contexts/config/config.types';
import { GetLinkFichaCatalograficaByMarca } from './ficha-catalografica.config.types';

export const getLinkFichaCatalograficaByMarca: GetLinkFichaCatalograficaByMarca = (marca) => {
  const marcasHabilitadas: Array<Brand> = ['estacio', 'ibmec', 'idomed', 'unitoledo', 'wyden'];

  const url = `https://ltechnology.com.br/gleison/`;

  return marcasHabilitadas.includes(marca) ? url : '';
};
