import { useConfig } from '@aura/contextos-frontend';
import LftLogotipo from '@lift/design-system-react-web/dist/components/LftLogotipo';
import LftTypography from '@lift/design-system-react-web/dist/components/LftTypography';
import React from 'react';
import { S } from './tapume.styles';

export const Tapume: React.FC = () => {
  const { brand } = useConfig();
  return (
    <S.Container data-section="page_tapume">
      <LftLogotipo alt={`Logotipo da marca ${brand}`} />
      <br />
      <LftTypography component="title" variant="h1">
        Desculpe, estamos enfrentando alguns desafios técnicos no momento
      </LftTypography>
      <br />
      <LftTypography component="paragraph" size="large">
        Nossa equipe está empenhada em resolver o problema o mais rápido possível.
      </LftTypography>
      <LftTypography component="paragraph" size="large">
        Por favor, tente novamente mais tarde.
      </LftTypography>
    </S.Container>
  );
};
