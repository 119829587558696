import { ThemeManagerProvider } from '@aura/temas-frontend';
import { LoadingDefault } from '@aura/templates-frontend';
import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import { getConfig } from './infra';

import { Assert } from '@aura/core-lib';
import { init as initApm } from '@elastic/apm-rum';
import { createRoot } from 'react-dom/client';
import { Cache, SWRConfig } from 'swr';
import { Tapume } from './components/tapume/tapume.component';
import { ToastManager } from './components/toast-manager/toast-manager.component';

const Bootstrap = lazy(() => import('./pages/bootstrap/Bootstrap.page'));

const Index: React.FC = () => {
  const environment = useMemo(getConfig, [getConfig]);

  const onLoading = <LoadingDefault />;
  const onError = <Tapume />;

  useEffect(() => {
    const readEnvironment = () => {
      switch (environment.ambiente.toLocaleLowerCase()) {
        case 'dev':
        case 'develop':
        case 'development':
          return 'development';
        case 'qld':
        case 'qa':
        case 'hml':
        case 'homolog':
          return 'homolog';
        case 'prd':
        case 'prod':
        case 'production':
          return 'production';
        case 'ci':
        case 'local':
        default:
          return null;
      }
    };
    const env = readEnvironment();
    if (env) {
      initApm({
        serviceName: `sava-webapp-educadores`,
        serverUrl: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
        serviceVersion: environment.marca,
        environment: env,
        distributedTracing: true,
        distributedTracingOrigins: [environment.API, environment.API_V2],
      });
    }
  }, []);

  function localStorageProvider() {
    // When initializing, we restore the data from `localStorage` into a map.
    const map = new Map(JSON.parse(localStorage.getItem('app-cache') ?? '[]'));

    // Before unloading the app, we write back all the data into `localStorage`.
    window.addEventListener('beforeunload', () => {
      const appCache = JSON.stringify(Array.from(map.entries()));
      localStorage.setItem('app-cache', appCache);
    });

    // We still use the map for write & read for performance.
    return map as Cache;
  }

  return (
    <SWRConfig
      value={{
        provider: localStorageProvider,
      }}
    >
      <ThemeManagerProvider brand={environment.marca}>
        <ToastManager />
        <Suspense fallback={onLoading}>
          <Bootstrap environment={environment} onLoading={onLoading} onError={onError} />
        </Suspense>
      </ThemeManagerProvider>
    </SWRConfig>
  );
};

const container = document.getElementById('root');

Assert.nonNullable(container, () => 'Container deve existir');

const root = createRoot(container);
root.render(<Index />);
