/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { ParaMsalConfiguration } from './msal.types';

const loggerCallback = (level: LogLevel, message: string, containsPii: boolean) => {
  if (containsPii) {
    return;
  }

  switch (level) {
    case LogLevel.Error:
      console.error(message);

      return;

    case LogLevel.Info:
      console.info(message);

      return;

    case LogLevel.Verbose:
      console.debug(message);

      return;

    case LogLevel.Warning:
      console.warn(message);

      return;
  }
};

const clearAuthority = (url?: string) => {
  const hasV2 = url?.indexOf('/v2.0');
  if (hasV2) {
    const result = url?.substring(0, hasV2);
    return result;
  }
  return url;
};

export const paraMsalConfiguration: ParaMsalConfiguration = ({ settings }) => {
  const authority = clearAuthority(settings.authority);

  return {
    auth: {
      clientId: settings.client_id!,
      authority,
      redirectUri: settings.redirect_uri,
      postLogoutRedirectUri: settings.redirect_uri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true,
    },
    system: {
      loggerOptions: {
        loggerCallback,
      },
    },
    scope: settings.scope!,
  };
};
